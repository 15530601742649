import { LineItem, Order } from '@commercelayer/sdk';
import { ProductAndBundleDataType } from '@model/product';
import { sendGTMEvent } from '@next/third-parties/google';
//@ts-ignore
import Cookies from 'js-cookie';

declare const dataLayer: Record<string, unknown>[];

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=it&client_type=gtm
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=it&client_type=gtm
 */

export const saveUtmParams = (queryParams: any) => {
  const utmAllowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const utmKeys = Object.keys(queryParams)
    .filter((key) => utmAllowedKeys.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = queryParams[key];
      return obj;
    }, {});
  if (Object.keys(utmKeys).length > 0) {
    Cookies.set('utm_params', JSON.stringify(utmKeys), { expires: 30 });
  }
};

export const getUtmParams = () => {
  const utmParams = Cookies.get('utm_params');
  if (utmParams) {
    return JSON.parse(utmParams);
  }
};

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=it&client_type=gtm#sign_up
 */
export const trackSignUp = () => {
  sendGTMEvent({ event: 'sign_up' });
};

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#login
 */
export const trackLogin = () => {
  sendGTMEvent({ event: 'login' });
};

export const trackProductView = (data: ProductAndBundleDataType) => {
  try {
    data.metadata.maxQuantity = data.maxQuantity;
    const lineItem: LineItem = {
      id: data.id,
      type: 'line_items',
      item_type: data.type == 'bundles' ? 'bundles' : 'skus',
      name: data.name,
      sku_code: data.skuCode,
      unit_amount_float: data.price.amount_float,
      quantity: 0,
      total_amount_float: data.price.amount_float,
      tax_amount_float: 0,
      created_at: null,
      updated_at: null,
      metadata: data.metadata,
    };
    const payload = {
      ...{
        event: 'view_item',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: data.price.currency_code,
          value: parseFloat(data.price.amount_float.toFixed(2)),
          items: gtmProducts([lineItem], true),
        },
      },
    };
    pushEcommAtIndex(1, payload);
  } catch (error) {
    console.error(error);
  }
};

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=it&client_type=gtm#select_item
 */
export const trackProductSelectView = (
  item: {
    item_id: string;
    item_name: string;
    index: number;
    item_list_name: string;
    price: number;
    quantity?: number;
  },
  currency?: string
) => {
  try {
    const payload = {
      event: 'select_item',
      ecommerce: {
        currency: currency,
        //item_list_id: 'related_products',
        item_list_name: item.item_list_name,
        items: [
          {
            item_id: item.item_id,
            item_name: item.item_name,
            index: item.index,
            item_list_name: item.item_list_name,
            price: item.price,
            quantity: item.quantity ?? 1,
          },
        ],
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=it&client_type=gtm#view_item_list
 */
export const trackProductListView = (
  item_list_name: string,
  products: { item_id: string; item_name: string; index: string; price: number }[],
  currency: string
) => {
  try {
    const items = products.map((p) => ({
      item_id: p.item_id,
      item_name: p.item_name,
      index: p.index,
      item_list_name: item_list_name,
      price: p.price,
      quantity: 1,
    }));
    const payload = {
      event: 'view_item_list',
      ecommerce: {
        currency: currency,
        //item_list_id: "related_products",
        item_list_name: item_list_name,
        items: items,
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddToCart = (
  lineItem: LineItem,
  quantity: number,
  index: number,
  itemListName: string
) => {
  try {
    const payload = {
      ...{
        event: 'add_to_cart',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: lineItem.currency_code,
          value: lineItem.unit_amount_float * quantity,
          items: gtmProducts([lineItem], false, quantity, index, itemListName),
        },
      },
    };

    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackRemoveFromCart = (lineItem: LineItem, quantity = lineItem.quantity) => {
  try {
    const payload = {
      ...{
        event: 'remove_from_cart',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: lineItem.currency_code,
          value: lineItem.unit_amount_float * quantity,
          items: gtmProducts([lineItem], false, quantity),
        },
      },
    };

    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackStepBeginCheckoutEvent = (order: Order) => {
  if (!order) return;
  try {
    const payload = {
      ...{
        event: 'begin_checkout',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currency_code,
          value: parseFloat(
            order.formatted_total_amount_with_taxes.replace(/[^\d.,]/g, '').replace(',', '.')
          ),
          items: gtmProducts(order.line_items),
        },
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackViewCartEvent = (order: Order) => {
  try {
    const payload = {
      ...{
        event: 'view_cart',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currency_code,
          value: parseFloat(
            order.formatted_total_amount_with_taxes.replace(/[^\d.,]/g, '').replace(',', '.')
          ),
          items: gtmProducts(order.line_items),
        },
      },
    };
    pushEcommAtIndex(1, payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackPurchase = (order: Order) => {
  try {
    if (!order) {
      return;
    }
    const shippingCostsItems = order.line_items.filter(
      (i) => i?.item_type == 'adjustments' && i?.name == 'shipping_cost'
    );
    const shippingCosts = shippingCostsItems.reduce(
      (accum, item) => accum + item.total_amount_float,
      0.0
    );
    const payload = {
      ...{
        event: 'purchase',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currency_code,
          transaction_id: order.number,
          value: order.total_amount_with_taxes_float,
          shipping: shippingCosts,
          tax: order.total_tax_amount_float,
          coupon: order.coupon_code || null,
          items: gtmProducts(order.line_items),
        },
      },
    };
    pushEcomm(payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddShippingInfo = (order: Order) => {
  if (!order) {
    return;
  }
  try {
    const payload = {
      ...{
        event: 'add_shipping_info',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currency_code,
          value: parseFloat(
            order.formatted_total_amount_with_taxes.replace(/[^\d.,]/g, '').replace(',', '.')
          ),
        },
      },
    };
    pushEcommAtIndex(1, payload);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddPaymentInfo = (order: Order) => {
  if (!order) {
    return;
  }
  try {
    const payload = {
      ...{
        event: 'add_payment_info',
        event_trigger: 'ecomm',
        ecommerce: {
          currency: order.currency_code,
          value: parseFloat(
            order.formatted_total_amount_with_taxes.replace(/[^\d.,]/g, '').replace(',', '.')
          ),
        },
      },
    };
    pushEcomm(payload);
  } catch {}
};

const gtmProducts = (
  lineItems: LineItem[],
  is_saleable = false,
  incQuantity?: number,
  index?: number,
  item_list_name?: string
) => {
  const skuLineItems = lineItems.filter((i) => i.item_type == 'skus' || i.item_type == 'bundles');
  return skuLineItems.map((lineItem) => {
    const item = {
      ...(index !== undefined ? { index: index } : {}),
      //@ts-ignore
      item_brand: null,
      item_name: lineItem.name,
      ...(!!item_list_name && { item_list_name }),
      item_id: lineItem.sku_code || lineItem.bundle_code,
      price: parseFloat(lineItem.unit_amount_float.toFixed(2)),
      quantity: incQuantity || lineItem.quantity || lineItem.metadata.maxQuantity,
      ...(is_saleable ? { is_saleable: lineItem.metadata.maxQuantity > 0 ? true : false } : {}),
    };
    return item;
  });
};

const pushEcomm = (payload: any) => {
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent(payload);
};

const pushEcommAtIndex = (start: number, payload: any) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.splice(start, 0, { ecommerce: null }, payload);
  }
};
