export const localStorageSetItem = (key: string, value: string) => {
  try {
    return localStorage.setItem(key, value);
  } catch (error) {
    console.error('localStorageSetItem', error);
  }
};
export const localStorageGetItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error('localStorageGetItem', error);
  }
};
export const localStorageRemoveItem = (key: string) => {
  try {
    return localStorage.removeItem(key);
  } catch (error) {
    console.error('localStorageRemoveItem', error);
  }
};
